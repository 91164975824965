import React from 'react';
import { Router } from '@reach/router';
import PrivateRoute from 'components/PrivateRoute';
import DashboardPage from 'views/DashboardPage';
import Workspace from 'workspace';

const Dashboard = () => {
  return (
    <Workspace>
      <Router>
        <PrivateRoute path="/dashboard" component={DashboardPage} />
      </Router>
    </Workspace>
  );
};

export default Dashboard;
